import React, { Fragment }  from 'react'
import { graphql} from 'gatsby'
import styled from 'styled-components';
import  { GatsbyImage }  from 'gatsby-plugin-image'
import { colors, mq, space, font } from '../consts/style';

import Seo from '../components/seo'
import Anim from '../components/animations/Anim'
import {
  PageWrapper,
  Text,
  FocusText,
  PageInner,
  PageTitle
} from '../components/Elements';



const GridBiographie = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: left;
  display: block;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  ${mq.tabletSmall_up`
    display: grid;
    grid-template-columns: 2fr 3fr;
    padding-bottom: 6.4rem;
  `}

  ${PageTitle} {
    padding-top:6.4rem;
    ${mq.tabletSmall`
    padding-top:4rem;
  `}
  }

`;

const ColGauche = styled.div`
    grid-column: 1/auto;
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
`;
const ColDroite = styled.div`
    padding-left: 6.4rem;
    grid-column: 2/auto;
    grid-row: 1/auto;
    ${mq.tabletSmall`
      max-width: 100%;
      padding-left: 0;
    `}
`;
const Background = styled.div`
    border-radius: 0.8rem;
    margin-left: -15rem;
    padding: 6.4rem 6.4rem 6.4rem 15rem;
    
    background-color: #f0fbf6;
    ${mq.tabletSmall`
      padding: 3.2rem;
      margin: 0 -3.2rem;
      border-radius: 0;
    `}
`;


const Sticky = styled.div`
  top: 3.2rem;
  position: sticky;
  margin-bottom: 6.4rem;
  width: 100%;
  text-align: center;
  ${mq.tabletSmall`
    margin-bottom: 0;
  `}
`;


const BiographiePage = ({data}) => {
  //console.log(data.page.seoMetaTags)
  return (
    <Fragment>
    <Seo meta={data.page.seoMetaTags} />
        <PageWrapper>
          <PageInner>
          <GridBiographie>
            <ColGauche>
              <Sticky>
              <Anim> 
                <GatsbyImage image={data.page.photoBiographie.gatsbyImageData} alt="Aurélie Lasherme, sophrologue professionnelle à Voiron (38, Isère), spécialisée dans la gestion du stress, la prévention du burnout et la préparation à des événements importants (examens, concours, représentations)"  style={{'borderRadius':'.8rem'}}/>
              </Anim>
             </Sticky>
            </ColGauche>
            <ColDroite>
            <PageTitle>{data.page.titre}</PageTitle>
               <FocusText dangerouslySetInnerHTML={{ __html: data.page.chapeau }}/> 
            
              <Background> 
                <Text dangerouslySetInnerHTML={{ __html: data.page.biographie }}/>
             </Background>
            </ColDroite>
          </GridBiographie>
          </PageInner>
        </PageWrapper>
          </Fragment>
)
}

export default BiographiePage

 
export const query = graphql`
  query bioPage($locale: String!) {
    page: datoCmsBiographiePage(locale: {eq: $locale}) {
      titre
      chapeau
      biographie
      photoBiographie {
        gatsbyImageData(
          placeholder: BLURRED,
          forceBlurhash: false,           
        )
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }      
`
