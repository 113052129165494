import React from 'react'
import { animated, config, useSpring } from 'react-spring'


const Anim = ({children}) => {
  
  const pageAnimation = useSpring({
    config: config.slow,
    from: {  transform: 'translate3d(0, 50px, 0)'  },
    to: {  transform: 'translate3d(0, 0, 0)'  },
  })
  
  /*const pageAnimation2 = useSpring({
    config: config.normal,
    from: {  transform: 'translate3d(0, 50px, 0)'  },
    to: {  transform: 'translate3d(0, 0, 0)'  },
  })*/
  

  return (
	<animated.div style={pageAnimation}>
		{children}
	</animated.div>
  )

}

export default Anim
